import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Participants.scss";
import { Field, Form, Formik } from "formik";
import {
	getParticipantsList,
	getNewItemsOnScroll,
} from "../../../redux/actions";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/button/Button";
import profile from "services/profile";
import CustomMultiSelectParticipant from "components/CustomFormSelect/CustomMultiSelectParticipant";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ReactComponent as Search } from "assets/vector/search.svg";
import { ReactComponent as CloseFilter } from "assets/vector/close-filter.svg";
import { ReactComponent as FilterToggleButton } from "assets/vector/filter-toggle-button.svg";
import search_is_nothing_found from "assets/vector/participant/search-is-nothing-found.svg";
import defaultAva from "assets/vector/default-avatar.svg";
import { useObserver } from "hooks/useObserver";
import CustomFormSelectParticipants from "components/CustomFormSelect/CustomSelectParticipants";
import { programStatusList } from "helpers/programStatusList";

export default function ParticipantsPage({
	participantsData,
	defaultShowItems,
	setProgramStatus,
	programStatus,
}) {
	const currentUserInfo = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const lastElement = useRef();

	const { isLeadCoachDashboardPage } = useSelector((state) => state.dashboard);

	// offsetItems
	const userToken = useSelector((state) => state.auth.authToken);
	const participantsListCountByrefresh = useSelector(
		(state) => state.participantsList.participantsListNull
	);
	const dispatch = useDispatch();

	const { participantsListLoading } = useSelector(
		(state) => state.participantsList
	);

	const [participantsSearchValue, setParticipantsSearchValue] = useState("");
	const [offsetItems, setOffsetItems] = useState(defaultShowItems);
	const maxPages =
		participantsData && participantsData.total ? participantsData.total : 0;
	const [selectParticipantsFiltersValue, setSelectParticipantsFiltersValue] =
		useState({
			programStatus:
				currentUserInfo?.role !== "participant" ? programStatus : "",
			programs: [],
			languages: [],
			industry_background: [],
			functional_areas: [],
		});

	const [statusFilterToggle, setStatusFilterToggle] = useState(false);

	const newResultprograms = selectParticipantsFiltersValue.programs.map(
		function (element) {
			return element.id;
		}
	);

	const newResultFunctionalAreas =
		selectParticipantsFiltersValue.functional_areas.map(function (element) {
			return element.id;
		});

	const newResultIndustryBackground =
		selectParticipantsFiltersValue.industry_background.map(function (element) {
			return element.id;
		});

	const newResultLanguagesList = selectParticipantsFiltersValue.languages.map(
		function (element) {
			return element.id;
		}
	);

	const [programsOptions, setProgramsOptions] = useState(null);
	const [languageOptions, setLanguageOptions] = useState(null);
	const [industryBackgroundOptions, setIndustryBackgroundOptions] =
		useState(null);
	const [functionalAreaOptions, setFunctionalAreaOptions] = useState(null);
	const [focusSearch, setFocusSearch] = useState(false);

	const scrollSectionRef = useRef();
	const navigate = useNavigate();

	function handleNavigateToProfile(event, id) {
		event.preventDefault();
		navigate(`${id}`);
	}

	function handleNavigateToProgramme(event, id) {
		event.preventDefault();
		navigate(`/programmes/single-program/${id}`);
	}

	function handleParticipantsSearchChange(event) {
		setParticipantsSearchValue(event.target.value);

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			search: event.target.value,
			programStatus:
				currentUserInfo.role !== "participant"
					? selectParticipantsFiltersValue?.programStatus
					: "",
			programs: newResultprograms,
			functional_areas: newResultFunctionalAreas,
			industry_background: newResultIndustryBackground,
			languages: newResultLanguagesList,
		};

		dispatch(getParticipantsList(getFormValue));
	}

	const handleChangeSelect = (values) => {
		const newValuesPrograms = values.programs.map(function (element) {
			return element.id;
		});

		const newValuesFunctionalAreas = values.functional_areas.map(function (
			element
		) {
			return element.id;
		});

		const newValuesIndustryBackground = values.industry_background.map(
			function (element) {
				return element.id;
			}
		);

		const newValuesLanguagesList = values.languages.map(function (element) {
			return element.id;
		});

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			programStatus:
				currentUserInfo.role !== "participant" ? values.programStatus : "",
			search: participantsSearchValue,
			programs: newValuesPrograms,
			functional_areas: newValuesFunctionalAreas,
			industry_background: newValuesIndustryBackground,
			languages: newValuesLanguagesList,
		};
		setOffsetItems(defaultShowItems);
		dispatch(getParticipantsList(getFormValue));
		setSelectParticipantsFiltersValue(values);
		if (currentUserInfo.role !== "participant") {
			setProgramStatus(values.programStatus);
		}
	};

	const clearSearchField = () => {
		setParticipantsSearchValue("");

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			search: "",
			programStatus:
				currentUserInfo.role !== "participant"
					? programStatusList[0].value
					: "",
			programs: newResultprograms,
			functional_areas: newResultFunctionalAreas,
			industry_background: newResultIndustryBackground,
			languages: [],
		};
		setOffsetItems(defaultShowItems);
		if (currentUserInfo.role !== "participant") {
			setProgramStatus(programStatusList[0].value);
		}
		dispatch(getParticipantsList(getFormValue));
	};

	const getDefinedData = () => {
		profile
			.getDefinedData(userToken)
			.then((response) => {
				setProgramsOptions(response.programs);
				setLanguageOptions(response.languages);
				setIndustryBackgroundOptions(response.industryBackgrounds);
				setFunctionalAreaOptions(response.functionalAreas);
			})
			.catch((error) => console.log(error));
	};

	useObserver(
		lastElement,
		offsetItems <= maxPages,
		participantsListLoading,
		() => {
			let getNewItems = {
				offset: offsetItems,
				take: defaultShowItems,
				programStatus:
					currentUserInfo.role !== "participant"
						? selectParticipantsFiltersValue?.programStatus
						: "",
				search: participantsSearchValue,
				programs: newResultprograms,
				functional_areas: newResultFunctionalAreas,
				industry_background: newResultIndustryBackground,
				languages: newResultLanguagesList,
			};
			setOffsetItems(offsetItems + defaultShowItems);
			dispatch(getNewItemsOnScroll(getNewItems));
		}
	);

	useEffect(() => {
		getDefinedData();
	}, []);

	function scrollTopParticipants() {
		const currentScrollSectionRef = scrollSectionRef.current;
		if (currentScrollSectionRef) {
			currentScrollSectionRef.scrollTop = 0;
		}
	}

	const handleResetParticipants = () => {
		clearSearchField();
		scrollTopParticipants();

		let getFormValue = {
			offset: 0,
			take: defaultShowItems,
			programStatus:
				currentUserInfo.role !== "participant"
					? programStatusList[0].value
					: "",
			search: participantsSearchValue,
			programs: [],
			languages: [],
			industry_background: [],
			functional_areas: [],
		};
		dispatch(getParticipantsList(getFormValue));
		setOffsetItems(defaultShowItems);
		if (currentUserInfo.role !== "participant") {
			setProgramStatus(programStatusList[0].value);
		}
		setSelectParticipantsFiltersValue({
			programStatus:
				currentUserInfo.role !== "participant"
					? programStatusList[0].value
					: "",
			programs: [],
			languages: [],
			industry_background: [],
			functional_areas: [],
		});
	};

	return (
		<Formik
			onReset={handleResetParticipants}
			initialValues={{
				programStatus: programStatus,
				programs: [],
				languages: [],
				industry_background: [],
				functional_areas: [],
			}}
			onSubmit={(values) => handleChangeSelect(values)}
		>
			{({ handleReset, values }) => {
				return (
					<Form>
						<section className="page-participants">
							<div
								className={`participants-filters ${
									statusFilterToggle ? "open-filter" : ""
								}`}
							>
								<div className="mobile-filter-header">
									<h3>Filters</h3>
									<div className="close-filter">
										<CloseFilter onClick={() => setStatusFilterToggle(false)} />
									</div>
								</div>
								{currentUserInfo.role !== "participant" && (
									<div className="filter-item activity-status">
										<p className={`filter-group-title`}>Activity status</p>
										<Field
											setProgramStatus={setProgramStatus}
											programStatus={programStatus}
											name="programStatus"
											options={programStatusList}
											component={CustomFormSelectParticipants}
											placeholder="Active"
											isMulti={false}
										/>
									</div>
								)}
								<div className="filter-item coaching-programme">
									<p className={`filter-group-title`}>Coaching programme</p>
									<Field
										name="programs"
										options={programsOptions}
										defaultValue={values.programs}
										component={CustomMultiSelectParticipant}
										placeholder="All"
									/>
								</div>
								<div className="filter-item language">
									<p className={`filter-group-title`}>Language</p>
									<Field
										name="languages"
										options={languageOptions}
										defaultValue={values.languages}
										component={CustomMultiSelectParticipant}
										placeholder="All"
									/>
								</div>
								<div className="filter-item industry">
									<p className={`filter-group-title`}>Industry Background</p>
									<Field
										name="industry_background"
										options={industryBackgroundOptions}
										defaultValue={values.industry_background}
										component={CustomMultiSelectParticipant}
										placeholder="All"
									/>
								</div>
								<div className="filter-item functional-area">
									<p className={`filter-group-title`}>Functional area</p>
									<Field
										name="functional_areas"
										options={functionalAreaOptions}
										defaultValue={values.functional_areas}
										component={CustomMultiSelectParticipant}
										placeholder="All"
									/>
								</div>
								<div className="clear-all-filters">
									<Button
										onClick={handleReset}
										className="clear-btn"
										type="reset"
									>
										Clear all filters
									</Button>
								</div>
							</div>
							<div className="participants-list">
								<div className="participants-filter-search">
									<h2>Participants list</h2>
									<div className="search">
										<input
											name="participants-search"
											onFocus={() => setFocusSearch(!focusSearch)}
											onBlur={() => setFocusSearch(!focusSearch)}
											onChange={handleParticipantsSearchChange}
											type="text"
											value={participantsSearchValue}
											placeholder={`Key word for search...`}
										/>
										<div className={focusSearch ? "icon active" : "icon"}>
											<Search />
										</div>
										<div className="filter-toggle-block">
											<FilterToggleButton
												className={`toggle-filter`}
												onClick={() => setStatusFilterToggle(true)}
											/>
										</div>
									</div>
								</div>
								{participantsData &&
								participantsListCountByrefresh &&
								participantsData.participants.length > 0 ? (
									<Scrollbars
										ref={scrollSectionRef}
										className="list"
										autoHeight
										autoHeightMin="100%"
										autoHeightMax="100%"
									>
										{participantsData.participants.map((participant, index) => (
											<Link
												className={`view-profile__card ${
													participant.is_deactivated ? "deactivated" : ""
												}`}
												to={`${participant.id}`}
												key={participant.id}
											>
												<div className="item">
													<div className="participant-avatar">
														<img
															src={
																participant.avatar
																	? participant.avatar
																	: defaultAva
															}
															alt=""
														/>
													</div>
													<div className="participant-short-info">
														<h3 className={`name`}>
															<span>
																{participant.name} {participant.surname}
															</span>
															{participant.is_deactivated && (
																<div className="deactivated-label">
																	Deactivated
																</div>
															)}
														</h3>
														<div className="participant-block-wrapper">
															<div className="participant-left-block">
																{participant.location && (
																	<p className={`country`}>
																		{" "}
																		{participant.location}
																	</p>
																)}
																<p className="program-in-progress">
																	{participant.last_program}
																</p>
															</div>
															<div className="participant-right-block">
																{participant.completed_session_activities !==
																	null &&
																	participant.total_session_activities !==
																		null && (
																		<div className="sessions">
																			<div className="counter">
																				<span className={`from`}>
																					{
																						participant.completed_session_activities
																					}
																				</span>
																				<span className={`to`}>
																					{" "}
																					of{" "}
																					{
																						participant.total_session_activities
																					}{" "}
																					sessions
																				</span>
																			</div>
																		</div>
																	)}
															</div>
														</div>
													</div>
													<div className="participant-full-info">
														<button
															className="view-profile__btn"
															onClick={(event) =>
																handleNavigateToProfile(event, participant.id)
															}
														>
															View profile
														</button>
														{currentUserInfo?.role === "coach" &&
															!isLeadCoachDashboardPage && (
																<button
																	className="view-profile__btn"
																	onClick={(event) =>
																		handleNavigateToProgramme(
																			event,
																			participant.last_program_id
																		)
																	}
																>
																	View programme
																</button>
															)}
													</div>
												</div>
											</Link>
										))}
										<div ref={lastElement} />
									</Scrollbars>
								) : (
									<div className="empty-list">
										<img
											className={`search-is-nothing-found`}
											src={search_is_nothing_found}
											alt=""
										/>
										{participantsSearchValue !== "" && (
											<>
												<h2 className={`searched-value`}>
													Search request{" "}
													<span className={`text`}>
														“{participantsSearchValue}”
													</span>{" "}
													is nothing found
												</h2>

												<div
													className="clear-search-field"
													onClick={() => handleReset()}
												>
													Back to all participants list
												</div>
											</>
										)}
										<div ref={lastElement} />
									</div>
								)}
							</div>
						</section>
					</Form>
				);
			}}
		</Formik>
	);
}
