import React from "react";
import "./MyProfile.scss";
import { Link } from "react-router-dom";
import defaultAva from 'assets/vector/default-avatar.svg';
import { ReactComponent as DownloadCvSvg } from "assets/vector/my-profile/download-cv.svg";
import { ReactComponent as IconAttachedFileSvg } from "assets/vector/my-profile/icon-attached-file.svg";
import { ReactComponent as BulletSvg } from "assets/vector/bullet.svg";
import { ReactComponent as EditProfileSvg } from "assets/vector/my-profile/edit.svg";
import routes from "constants/routes";
import ShowMoreText from "react-show-more-text";
import moment from "moment";

import { useMediaQuery } from "react-responsive";
import mediaQuery from "constants/mediaQuery";
const { MOBILE_QUERY_767 } = mediaQuery;

export default function CoachesProfile({ getCurrentUserInfo }) {

    const { EDIT_MY_PROFILE } = routes;
    const isMobile = useMediaQuery({ maxDeviceWidth: MOBILE_QUERY_767 });
    const downloadCV = (cv) => {
        window.open(cv);
    };

    return (
        getCurrentUserInfo &&

        <div className="my-profile">

            <div className="short-info">

                <div className="my-avatar"><img src={getCurrentUserInfo.avatar ? getCurrentUserInfo.avatar : defaultAva} alt="" /></div>

                <div className="my-short-info">
                    <h3 className={`name`}>{getCurrentUserInfo.name} {getCurrentUserInfo.surname} </h3>
                    <p className={`country`}>{getCurrentUserInfo.location}</p>
                </div>


                {
                    getCurrentUserInfo.cv &&

                    <div className="download-cv" onClick={() => downloadCV(getCurrentUserInfo.cv)} >
                        <div className="text-download">Download CV</div>
                    </div>
                }

                {
                    isMobile ?
                        <Link className={`edit-profile-icon`} to={EDIT_MY_PROFILE}>
                            <EditProfileSvg />
                        </Link>
                        :
                        <Link className="view-profile edit-profile" to={EDIT_MY_PROFILE}>
                            Edit
                        </Link>
                }


            </div>

            {
                (
                    getCurrentUserInfo.summary ||
                    getCurrentUserInfo.languages.length > 0 ||
                    getCurrentUserInfo.functional_areas.length > 0 ||
                    getCurrentUserInfo.seniority_level ||
                    getCurrentUserInfo.coaching_areas.length > 0 ||
                    (getCurrentUserInfo.coaching_experience.years && getCurrentUserInfo.coaching_experience.hours && getCurrentUserInfo.certification) ||
                    getCurrentUserInfo.certification ||
                    getCurrentUserInfo.industry_background.length > 0 ||
                    getCurrentUserInfo.geography.length > 0 ||
                    getCurrentUserInfo.work_experience.length > 0 ||
                    getCurrentUserInfo.publications.length > 0
                )

                &&

                <div className="full-info">

                    {
                        getCurrentUserInfo.summary &&

                        <div className="section-info-block summary">
                            <h3 className="block-title">Summary</h3>
                            <ShowMoreText
                                /* Default options */
                                lines={3}
                                more='Show more'
                                less='Show less'
                                className='summary-description'
                                anchorClass='show-more'
                                // onClick={this.executeOnClick}
                                expanded={false}
                                keepNewLines={true}
                            >
                                {getCurrentUserInfo.summary}
                            </ShowMoreText>

                        </div>
                    }

                    {
                        getCurrentUserInfo.languages.length > 0 &&

                        <div className="section-info-block languages">
                            <h3 className="block-title">Languages</h3>

                            <div className="languages-block">
                                {
                                    getCurrentUserInfo.languages.map(
                                        (element, index) => (
                                            <div key={index} className="language">

                                                <img className={`flag`} src={element.flag} alt="language flag" />
                                                <div className="language">{element.name}</div>
                                                <div className="experience">{element.level.name}</div>
                                            </div>
                                        )
                                    )
                                }
                            </div>

                        </div>
                    }

                    {
                        getCurrentUserInfo.functional_areas.length > 0 &&
                        <div className="section-info-block functional-area">
                            <h3 className="block-title">Functional Area</h3>

                            <div className="block-items">
                                {
                                    getCurrentUserInfo.functional_areas.map(
                                        (element, index) => (
                                            <span key={index} className="single-item">{element.name}</span>
                                        )
                                    )
                                }
                            </div>

                        </div>
                    }

                    {
                        getCurrentUserInfo.seniority_level && getCurrentUserInfo.seniority_level.name &&

                        <div className="section-info-block seniority-level">
                            <h3 className="block-title">Seniority Level</h3>

                            <div className="block-items">

                                <span className="single-item">{getCurrentUserInfo.seniority_level.name}</span>

                            </div>
                        </div>
                    }


                    {

                        ((getCurrentUserInfo.coaching_areas && getCurrentUserInfo.coaching_areas.length > 0) || getCurrentUserInfo.coaching_experience.hours || getCurrentUserInfo.coaching_experience.years || getCurrentUserInfo.certification) &&

                        <div className="section-info-block coaching-info">

                            {

                                getCurrentUserInfo.coaching_areas &&
                                getCurrentUserInfo.coaching_areas.length > 0 &&

                                <>
                                    <h3 className="block-title">Coaching information</h3>
                                    <div className="block-items">
                                        {
                                            getCurrentUserInfo.coaching_areas.map(
                                                (element, index) => (
                                                    <span key={index} className="single-item">{element.name}</span>
                                                )
                                            )
                                        }
                                    </div>
                                </>
                            }

                            {

                                <div className="coaching-experience">

                                    {
                                        getCurrentUserInfo.coaching_experience.years &&
                                        <div className="coaching-experience-item">
                                            <BulletSvg className={`icon-bullet`} />
                                            <div className="coaching-information-label">Coaching experience</div>
                                            <div className="coaching-information-value">{getCurrentUserInfo.coaching_experience.years} years</div>
                                        </div>
                                    }

                                    {
                                        getCurrentUserInfo.coaching_experience.hours &&
                                        <div className="coaching-experience-item">
                                            <BulletSvg className={`icon-bullet`} />
                                            <div className="coaching-information-label">Number of coached hours</div>
                                            <div className="coaching-information-value">{getCurrentUserInfo.coaching_experience.hours} hours</div>
                                        </div>
                                    }

                                    {
                                        getCurrentUserInfo.certification &&

                                        <div className="coaching-experience-item">
                                            <BulletSvg className={`icon-bullet`} />
                                            <div className="coaching-information-label">Certifications</div>
                                            <div className="coaching-information-value">{getCurrentUserInfo.certification}</div>
                                        </div>
                                    }

                                </div>
                            }


                        </div>
                    }


                    {
                        getCurrentUserInfo.industry_background.length > 0 &&
                        <div className="section-info-block industry-background">
                            <h3 className="block-title">Industry Background</h3>

                            <div className="block-items">
                                {
                                    getCurrentUserInfo.industry_background.map(
                                        (element, index) => (
                                            <span key={index} className="single-item">{element.name}</span>
                                        )
                                    )
                                }
                            </div>
                        </div>
                    }

                    {
                        getCurrentUserInfo.geography.length > 0 &&

                        <div className="section-info-block geography">
                            <h3 className="block-title">Geography</h3>
                            <div className="block-items">
                                {
                                    getCurrentUserInfo.geography.map(
                                        (element, index) => (
                                            <span key={index} className="single-item">{element.name}</span>
                                        )
                                    )
                                }

                            </div>
                        </div>
                    }

                    {
                        getCurrentUserInfo.work_experience.length > 0 &&
                        <div className="section-info-block work-experience">
                            <h3 className="block-title">Work experience</h3>

                            <div className="work-experience-block">
                                {

                                    getCurrentUserInfo.work_experience &&

                                    getCurrentUserInfo.work_experience.map(
                                        (element, index) => (
                                            <div key={index} className="experience-item">

                                                <div className="experience-item-left">
                                                    <div className="position">{element.position}</div>
                                                    <div className="company">{element.company}</div>
                                                </div>

                                                <div className="experience-item-right">
                                                    <div className="date">
                                                        <span className="from">{moment(element.date_from).format("MMMM YYYY")}</span> - <span className="to">{element.date_to ? moment(element.date_to).format("MMMM YYYY") : "Present"}</span>
                                                    </div>
                                                    <div className="job-responsibilities">{element.responsibilities}</div>
                                                </div>

                                            </div>
                                        )
                                    )
                                }
                            </div>


                        </div>
                    }


                    {
                        getCurrentUserInfo.publications.length > 0 &&

                        <div className="section-info-block publications">
                            <h3 className="block-title">Publications</h3>

                            <div className="publications-block">
                                {

                                    getCurrentUserInfo.publications &&

                                    getCurrentUserInfo.publications.map(
                                        (element, index) => (
                                            <div key={index} className="publications-item">

                                                <div className="publications-item-info">
                                                    <div className="item-author">{element.author}</div>
                                                    <div className="item-name">{element.name}</div>
                                                    <div className="item-publishing-house">{element.publishing_house}</div>
                                                    <div className="item-overview">{element.overview}</div>
                                                </div>

                                            </div>
                                        )
                                    )
                                }
                            </div>


                        </div>
                    }

                </div>
            }

        </div>
    )
}
