import React, { useEffect, useRef } from "react";
import "./Modal.scss";
import { CSSTransition } from "react-transition-group";
import CrossIcon from "components/icons/CrossIcon";
import EventSessionModal from "components/modal/EventSessionModal/EventSessionModal";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
	CLOSE_MODAL,
	SESSION_EVENT_MODAL,
	DECLINE_MODAL,
	CHOOSE_COACH_MODAL,
	FIND_ANOTHER_COACH_MODAL,
	GOOGLE_CANCEL_AUTH_MODAL,
	CREATE_SESSION_MODAL,
	SESSION_REQUEST_MODAL,
	CALENDAR_EVENT_MODAL,
	CAREER_COACHING_QUESTIONNAIRE_MODAL,
	QUESTIONNAIRE_LANGUAGE_MODAL,
	EDIT_SCHEDULE_MODAL,
	GENERATE_INVOICE_MODAL,
} from "../../redux/constants/modal";
import ChooseCoachModal from "./ChooseCoachModal/ChooseCoachModal";
import DeclineModal from "components/modal/EventSessionModal/DeclineModal/DeclineModal";
import DefaultModal from "components/modal/DefaultModal/DefaultModal";
import GoogleCancelAuthModal from "components/modal/GoogleCancelAuthModal/GoogleCancelAuthModal";
import CreateSessionModal from "components/modal/CreateSessionModal/CreateSessionModal";
import RequestSessionModal from "components/modal/RequestSessionModal/RequestSessionModal";
import CalendarEventModal from "components/modal/CalendarEventModal/CalendarEventModal";
import CareerCoachingQuestionnaireModal from "./CareerCoachingQuestionnaireModal/CareerCoachingQuestionnaireModal";
import QuestionnaireLanguageModal from "./QuestionnaireLanguageModal/QuestionnaireLanguageModal";
import EditScheduleModal from "./EditScheduleModal/EditScheduleModal";
import GenerateInvoiceModal from "./GenerateInvoiceModal/GenerateInvoiceModal";

const Modal = () => {
	const dispatch = useDispatch();
	const nodeRef = useRef(null);
	const node = useRef();
	const isModalOpen = useSelector((state) => state.modal.open);
	const modalType = useSelector((state) => state.modal.modalType);
	const modalData = useSelector((state) => state.modal.modalData);

	const closeModal = () => {
		dispatch({ type: CLOSE_MODAL });
	};

	const outsideClick = (e) => {
		if (!node.current.contains(e.target)) {
			if (modalType !== CAREER_COACHING_QUESTIONNAIRE_MODAL) {
				dispatch({ type: CLOSE_MODAL });
			}
		}
	};

	const renderModalContent = () => {
		switch (modalType) {
			case SESSION_EVENT_MODAL:
				return <EventSessionModal closeModal={closeModal} />;
			case CREATE_SESSION_MODAL:
				return (
					<CreateSessionModal closeModal={closeModal} modalData={modalData} />
				);
			case SESSION_REQUEST_MODAL:
				return (
					<RequestSessionModal closeModal={closeModal} modalData={modalData} />
				);
			case DECLINE_MODAL:
				return <DeclineModal closeModal={closeModal} modalData={modalData} />;
			case CHOOSE_COACH_MODAL:
				return (
					<ChooseCoachModal
						closeModal={closeModal}
						title="Congratulations!"
						description="Now you can proceed with coaching programme"
					/>
				);
			case FIND_ANOTHER_COACH_MODAL:
				return (
					<ChooseCoachModal
						closeModal={closeModal}
						title="Congrats!"
						description="We received your request and will suggest you another coach. Now you can proceed to your profile."
					/>
				);
			case GOOGLE_CANCEL_AUTH_MODAL:
				return (
					<GoogleCancelAuthModal
						title={
							"Are you sure that you want to delete the connected google account?"
						}
						closeModal={closeModal}
					/>
				);
			case CALENDAR_EVENT_MODAL:
				return <CalendarEventModal />;
			case EDIT_SCHEDULE_MODAL:
				return <EditScheduleModal />;
			case CAREER_COACHING_QUESTIONNAIRE_MODAL:
				return <CareerCoachingQuestionnaireModal closeModal={closeModal} />;
			case QUESTIONNAIRE_LANGUAGE_MODAL:
				return <QuestionnaireLanguageModal closeModal={closeModal} />;
			case GENERATE_INVOICE_MODAL:
				return (
					<GenerateInvoiceModal closeModal={closeModal} modalData={modalData} />
				);

			default:
				return <DefaultModal closeModal={closeModal} modalData={modalData} />;
		}
	};

	useEffect(() => {
		isModalOpen
			? (document.body.style.overflow = "hidden")
			: (document.body.style.overflow = "");
	}, [isModalOpen]);

	return (
		<CSSTransition
			in={isModalOpen}
			timeout={800}
			unmountOnExit
			classNames="modal__overlay-"
			nodeRef={nodeRef}
		>
			<div
				ref={nodeRef}
				className={`modal__overlay ${modalType?.toLowerCase()}`}
				onClick={outsideClick}
			>
				<Scrollbars
					autoHeight
					autoHeightMax="100%"
					style={{
						width: "auto",
						backgroundColor: "white",
						borderRadius: "12px",
					}}
					className="ScrollbarsCustom"
				>
					<div className="modal" ref={node}>
						<CrossIcon className="modal__close" onClick={closeModal} />
						{renderModalContent()}
					</div>
				</Scrollbars>
			</div>
		</CSSTransition>
	);
};

export default Modal;
