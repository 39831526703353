import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { history, store } from "./redux/store";
import { Provider } from "react-redux";
import "./variables.scss";
import "./_breakpoints.scss";
import "./index.scss";
import "react-quill-new/dist/quill.snow.css";
import "./assets/css/base.css";
import Root from "./containers/root";
import {
	getUserTokenFromStorage,
	getSchoolIdFromStorage,
	getTimeZoneNotificationStatusFronStorage,
	getSurveyDataFromStorage,
} from "services/auth";
import {
	updateQuestionnaireLanguageFromLocalstorage,
	updateSchoolId,
	updateSurveyDataFromLocalstorage,
	updateTimezoneNotificationStatusFromStorage,
	updateToken,
} from "./redux/actions";
import Modal from "components/modal/Modal";
import Notification from "components/notification/Notification";
import { getQuestionnaireLanguageFromStorage } from "services/questionnaireLanguage";
import TimezoneNotification from "components/timezoneNotification/TimezoneNotification";
import SideMenuNotifications from "components/SideMenuNotifications/SideMenuNotifications";

const userToken = getUserTokenFromStorage();
const schoolId = getSchoolIdFromStorage();
const questionnaireLanguage = getQuestionnaireLanguageFromStorage();
const timeZoneNotificationStatusFronStorage =
	getTimeZoneNotificationStatusFronStorage();
const surveyData = getSurveyDataFromStorage();

const domNode = document.getElementById("root");
const root = createRoot(domNode);

if (userToken) {
	store.dispatch(updateToken(userToken));
}

if (surveyData) {
	store.dispatch(updateSurveyDataFromLocalstorage(JSON.parse(surveyData)));
}

if (schoolId) {
	store.dispatch(updateSchoolId(schoolId));
}

if (questionnaireLanguage) {
	store.dispatch(
		updateQuestionnaireLanguageFromLocalstorage(questionnaireLanguage)
	);
}
if (timeZoneNotificationStatusFronStorage) {
	store.dispatch(
		updateTimezoneNotificationStatusFromStorage(
			timeZoneNotificationStatusFronStorage
		)
	);
}

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<Notification />
				<Modal />
				<TimezoneNotification />
				<SideMenuNotifications />
				<Root />
			</Router>
		</Provider>
	</React.StrictMode>
);
