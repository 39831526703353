import moment from "moment-timezone";

export const checkCompleteActivityPermission = (activityType) => {
	switch (activityType) {
		case "session":
		case "session_and_evaluation":
		case "form":
			return false;
		default:
			return true;
	}
};

export const checkBlockedActivity = (
	programTopics = [],
	topicId,
	prevSteps = [],
	coachId
) => {
	if (!coachId) {
		return true;
	}

	if (programTopics && topicId) {
		const prevStepsActivities = programTopics.reduce((activities, topic) => {
			const dependingActivities = topic.filter(
				(activity) =>
					(activity.type !== "module" && prevSteps.includes(activity.id)) ||
					(activity.type === "module" &&
						activity.is_disabled &&
						activity.id === topicId)
			);

			if (dependingActivities) {
				activities.push(...dependingActivities);
			}

			return activities;
		}, []);

		const prevIncompleteSteps = prevStepsActivities.filter(
			(activity) => !activity.completed
		);

		return prevIncompleteSteps.length > 0;
	}

	return false;
};

const isSessionDeadline = (dateStart, intervalHours, timeZone) => {
	const startSessionDate = moment.tz(dateStart, timeZone);
	const currentDate = moment.tz(timeZone);
	const deadLine = startSessionDate.clone().subtract(intervalHours, "h");

	return currentDate.isBefore(deadLine);
};

export const isSessionCompleted = (session) => session?.status === "completed";

export const isSessionExpired = (session, sessionDuration = 0, timeZone) => {
	if (session.status === "completed") {
		return true;
	}

	const startSessionDate = moment.tz(session?.date_start, timeZone);
	const currentDate = moment.tz(timeZone);
	const deadLine = startSessionDate.clone().add(sessionDuration, "m");

	return currentDate.isAfter(deadLine);
};

export const isEditAllowed = (
	session = {},
	userRole,
	isSessionOrganizer,
	isUserSessionEditor,
	hoursBeforeAction = 0,
	timeZone,
	duration
) => {
	const { organizer, date_start } = session;
	const isAdminOrganizer = organizer?.role === "school_admin";
	const isParticipant = userRole === "participant";

	if (isAdminOrganizer) return false;

	if (isSessionOrganizer && isUserSessionEditor) {
		if (isParticipant)
			return isSessionDeadline(date_start, hoursBeforeAction, timeZone);

		return true;
	}

	if (isParticipant && session.session_type === "group") return false;

	if (isParticipant && session.status !== "pending") {
		return isSessionDeadline(date_start, hoursBeforeAction, timeZone);
	}

	if (
		session.status === "pending" &&
		isSessionExpired(session, duration, timeZone) &&
		!isParticipant
	)
		return true;

	return session.status !== "pending";
};

export const isDeclineAllowed = (
	session = {},
	userRole,
	hoursBeforeAction = 0,
	timeZone,
	duration,
	userId
) => {
	const { date_start } = session;
	const isGroupSession = session.session_type === "group";
	const isIndividualSession = session.session_type === "individual";
	const isParticipant = userRole === "participant";
	const isCoach = userRole === "coach";
	const currentParticipant = session.participants.find(
		(participant) => participant.id === userId
	);
	const hasParticipantAccepted =
		currentParticipant?.invite_status !== "pending";
	const hasEverybodyAccepted = session.status !== "pending";

	if (session.status === "completed" || session.status === "late_canceled") {
		return false;
	}

	if (isCoach) return true;

	if (isParticipant) {
		if (isGroupSession && hasParticipantAccepted) {
			return isSessionDeadline(date_start, hoursBeforeAction, timeZone);
		} else if (isIndividualSession && hasEverybodyAccepted) {
			return isSessionDeadline(date_start, hoursBeforeAction, timeZone);
		} else if (
			isIndividualSession &&
			hasParticipantAccepted &&
			!hasEverybodyAccepted
		) {
			return false;
		}

		return !isSessionExpired(session, duration, timeZone);
	}

	return false;
};

export const isCompleteSessionAllowed = (
	session,
	userInfo = {},
	minutesBeforeCompleteSession = 0,
	timezone,
	sessionDuration
) => {
	const { userRole, userId } = userInfo;
	const isCurrentCoach =
		userRole === "coach" &&
		session.coaches.find((coach) => coach.id === userId);
	const currentDate = moment.tz(timezone);
	const sessionCompleteTime = moment
		.tz(session?.date_start, timezone)
		.add(sessionDuration - minutesBeforeCompleteSession, "m");

	return isCurrentCoach && currentDate.isSameOrAfter(sessionCompleteTime);
};

const isSessionCancelTime = (dateStart, intervalHours, timeZone) => {
	const startSessionDate = moment.tz(dateStart, timeZone);
	const currentDate = moment.tz(timeZone);
	const deadLine = startSessionDate.clone().subtract(intervalHours, "h");

	return currentDate.isAfter(deadLine);
};

export const isLateCancelAllowed = (
	session,
	userRole,
	hoursBeforeAction,
	timeZone
) => {
	const isSessionConfirmed =
		session.status === "confirmed" || session.status === "pending";
	const isCoach = userRole === "coach";
	const isCancelTime = isSessionCancelTime(
		session.date_start,
		hoursBeforeAction,
		timeZone
	);

	return isSessionConfirmed && isCoach && isCancelTime;
};

export const isSessionEditor = (session, userRole, userId) => {
	let userInvitationStatus = "";

	if (userRole === "coach") {
		userInvitationStatus = session.coaches.find(
			(coach) => coach.id === userId
		)?.invite_status;
	} else {
		userInvitationStatus = session.participants.find(
			(participant) => participant.id === userId
		)?.invite_status;
	}

	return session.status === "pending" && userInvitationStatus === "accepted";
};

export const transformDatePickerDate = (date) => {
	if (date) {
		return moment(date, moment.ISO_8601).local().format("YYYY-MM-DD");
	}
};

export const areIncompleteActivities = (activities) => {
	const incompleteActivities = activities.find(
		(activity) => activity.type !== "module" && activity.completed === false
	);

	return Boolean(incompleteActivities);
};

export const completeTopic = (activities) => {
	const hasIncompleteActivities = areIncompleteActivities(activities);

	if (hasIncompleteActivities) {
		return activities;
	}

	return activities.map((activity) => {
		if (activity.type === "module") {
			activity["completed"] = true;
		}

		return activity;
	});
};

export const isSessionRequestAllowed = (programs = [], programId) =>
	!!programs.find(
		(program) =>
			program.id === programId && program.can_request_additional_session
	);

export const hasSessionRequestDone = (programs, programId) =>
	!!programs.find(
		(program) =>
			program.id === programId && program.additional_session_request_sent
	);

export const hasProgramSessions = (programTopics = []) => {
	const activities = programTopics.flat();

	return !!activities.find(
		(activity) =>
			activity.type === "session" || activity.type === "session_and_evaluation"
	);
};

export const getUserProfileData = (
	role = "",
	programId,
	programs = [],
	coachId
) => {
	const userData = {};
	const {
		coach_avatar,
		coach_name,
		participant: { avatar, name, id } = {},
	} = programs?.find((program) => program.id === programId) || {};

	if (role === "coach") {
		userData.name = name;
		userData.avatar = avatar;
		userData.id = id;
		userData.role = "participant";
	} else if (role === "participant") {
		userData.name = coach_name;
		userData.avatar = coach_avatar;
		userData.id = coachId;
		userData.role = "coach";
	}

	return userData;
};
