import React from "react";
import defaultAva from "assets/vector/default-avatar.svg";
import uniqid from "uniqid";

const TitleAccessorFunction = (e) => {
	return (
		<>
			<div className="event__title">{e.title}</div>
			<ul className="avatars">
				{e.members?.coaches
					? e.members.coaches.map((member_1) => {
							return (
								<li key={uniqid()}>
									<img
										src={member_1.avatar ? member_1.avatar : defaultAva}
										alt="avatar"
									/>
								</li>
							);
					  })
					: null}
				{e.members?.participants
					? e.members.participants.map((member_2) => {
							return (
								<li key={uniqid()}>
									<img
										src={member_2.avatar ? member_2.avatar : defaultAva}
										alt="avatar"
									/>
								</li>
							);
					  })
					: null}
			</ul>
			{e.calendar_id && (
				<div className="google-icon">
					<span>{e.calendar_id}</span>
				</div>
			)}
		</>
	);
};

export default TitleAccessorFunction;
