import React, { useEffect, useState } from "react";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import UserInformation from "./userInformation/userInformation";
import MenuToggle from "components/menuToggle/menuToggle";
import {
	getUnreadNotifications,
	getUserNotifications,
	getPlatformSettings,
	updateUserSettings,
} from "../../../redux/actions";
import CustomPlatformSettings from "services/platformSettings/platformSettings";

export default function Header({ ShowProgramme, toggleMenu }) {
	const dispatch = useDispatch();
	const pageTitle = useSelector((state) => state.currentPageTitle.pageTitle);
	const showDashboardPage = useSelector(
		(state) => state.dashboard.showDashboardPage
	);
	const getCurrentUserInfo =
		useSelector((state) => state.currentUserInfo.currentUserInfo) || {};
	const platformSettings = useSelector(
		(state) => state.platformSettings.platformSettings
	);
	const { isLeadCoachDashboardPage } = useSelector((state) => state.dashboard);

	const [checkUserRole, setCheckUserRole] = useState(false);

	if (platformSettings) {
		localStorage.setItem(
			"platformSettings",
			JSON.stringify(platformSettings.settings)
		);
		CustomPlatformSettings();
	} else {
		localStorage.removeItem("platformSettings");
		CustomPlatformSettings();
	}

	useEffect(() => {
		if (getCurrentUserInfo?.use_local_tz === true) {
			const values = { timezone: moment.tz.guess() };

			if (getCurrentUserInfo?.timezone !== moment.tz.guess()) {
				dispatch(updateUserSettings(values));
			}
		}
	}, [getCurrentUserInfo.use_local_tz, getCurrentUserInfo.timezone, dispatch]);

	useEffect(() => {
		if (window.localStorage.getItem("school_id")) {
			dispatch(getPlatformSettings(window.localStorage.getItem("school_id")));
		}

		if (getCurrentUserInfo && getCurrentUserInfo.role === "school_admin") {
			setCheckUserRole(true);
		}
	}, [getCurrentUserInfo]);

	useEffect(() => {
		if (getCurrentUserInfo?.role === "school_admin") {
			// used to optimize the loading of the Dashboard page for school admins
			if (showDashboardPage) {
				dispatch(getUserNotifications());
				dispatch(getUnreadNotifications());
			}
		} else {
			dispatch(getUserNotifications());
			dispatch(getUnreadNotifications());
		}
	}, [getCurrentUserInfo, showDashboardPage]);

	return (
		<header className={`header`}>
			<MenuToggle toggleMenu={toggleMenu} />

			<div className="header-left">
				<div className="header-title-page gap-6">
					<h1 className={`title`}>{pageTitle}</h1>
					{getCurrentUserInfo?.role === "coach" &&
						getCurrentUserInfo.is_lead_coach && (
							<p className="text-blue-500">
								{isLeadCoachDashboardPage ? "Lead Coach" : "Coach"}
							</p>
						)}
				</div>
			</div>

			<div
				className={`header-right ${
					ShowProgramme && checkUserRole ? `show-programme` : `hide-programme`
				}`}
			>
				<UserInformation />
			</div>
		</header>
	);
}
