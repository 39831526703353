import React, { useEffect, useState } from "react";
import "./CategoryDescriptionEditor.scss";
import CustomCategoryEditSelect from "components/CustomFormSelect/CustomCategoryEditSelect";
import Button from "components/button/Button";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill-new";

const CategoryDescriptionEditor = ({
	selectLabel,
	textLabel,
	hideCategoryEditor,
	handleSubmit,
	defaultCategory = null,
	defaultText = "",
	options,
	itemId,
	isTodo,
	selectPlaceholder,
}) => {
	const { timezone } = useSelector(
		(state) => state.currentUserInfo.currentUserInfo
	);
	const [category, setCategory] = useState(defaultCategory);
	const [editedText, setEditedText] = useState(defaultText);
	const initialMultiFields = { text: "", dueDate: "" };
	const [multiField, setMultiField] = useState([{ ...initialMultiFields }]);
	const [error, setErrors] = useState({});
	let closeTimerId = null;
	const tomorrowWithTimeZone = new Date(
		moment.tz(timezone).add(1, "day").format("YYYY/MM/DD")
	);

	useEffect(() => () => {
		clearTimeout(closeTimerId);
	});

	const submitData = () => {
		// TODO need refactoring
		const minTextChars = 3;

		if (
			category &&
			!isTodo &&
			editedText &&
			editedText.trim().length >= minTextChars
		) {
			handleSubmit(itemId, category?.id, editedText);

			// TODO connect with redux
			hideCategoryEditor();
			closeTimerId = setTimeout(hideCategoryEditor, 300);
		} else if (isTodo && multiField.length) {
			const errors = [];

			multiField.forEach((field, index) => {
				if (!field.text) {
					errors[index] = "Field required";
				} else if (field.text && field.text.trim().length < minTextChars) {
					errors[index] = "Text should be 3 chars min.";
				}
			});

			if (!errors.length) {
				multiField.forEach((field, index) => {
					console.log("field", field);
					setTimeout(() => handleSubmit(category?.id, field), 400 * index);
				});

				hideCategoryEditor();
			} else {
				setErrors({ multiField: errors });
			}
		} else {
			// TODO refactor errors
			const errors = {};

			if (!category && !isTodo) {
				errors["select"] = "Field required";
			}

			if (!editedText) {
				errors["textarea"] = "Field required";
			} else if (editedText.trim().length < minTextChars) {
				errors["textarea"] = "Text should be 3 chars min.";
			}

			setErrors(errors);
		}
	};

	const handleMultiFieldInput = (e, index) => {
		const values = [...multiField];
		values[index]["text"] = e;

		setMultiField(values);
	};

	const handleMultiFieldDueDate = (date, index) => {
		const values = [...multiField];
		values[index]["dueDate"] = date;

		setMultiField(values);
	};

	const addTextArea = () => {
		setMultiField([...multiField, { ...initialMultiFields }]);
	};

	const dynamicTextAreas = multiField.map((field, index) => {
		const dueDateText = field.dueDate
			? moment(field.dueDate).format("DD-MM-YYYY")
			: "+ Add due date";

		return (
			<div key={index} className="category-editor__multi-row">
				<div className="category-editor__label">{textLabel}</div>

				<ReactQuill
					theme="snow"
					value={field.text}
					onChange={(e) => handleMultiFieldInput(e, index)}
				/>
				{error.multiField && error.multiField[index] && (
					<div className="category-editor__error">
						{error.multiField[index]}
					</div>
				)}
				<DatePicker
					selected={field.dueDate}
					className={`tabs__category-edit date-picker-kit ${
						field.dueDate ? "date-picker-kit--selected" : ""
					}`}
					calendarClassName={"date-picker-kit__calendar"}
					popperClassName={"date-picker-kit__popper"}
					customInput={<button>{dueDateText}</button>}
					minDate={tomorrowWithTimeZone}
					onChange={(date) => handleMultiFieldDueDate(date, index)}
				/>
			</div>
		);
	});

	return (
		<div className="category-editor">
			{options && (
				<>
					<div className="category-editor__label">{selectLabel}</div>
					<CustomCategoryEditSelect
						name={category}
						className={"category-editor__select"}
						setCategory={setCategory}
						placeholder={selectPlaceholder}
						category={category}
						error={error.select}
						options={options}
					/>
				</>
			)}
			{isTodo ? (
				dynamicTextAreas
			) : (
				<>
					<div className="category-editor__label">{textLabel}</div>
					<ReactQuill
						theme="snow"
						value={editedText}
						onChange={setEditedText}
					/>
					{error.textarea && (
						<div className="category-editor__error">{error.textarea}</div>
					)}
				</>
			)}
			<div className="category-editor__btn-group">
				{isTodo && (
					<button className="tabs__category-edit" onClick={addTextArea}>
						+ Add Action item
					</button>
				)}
				<Button
					loading={false}
					type="button"
					disabled={false}
					onClick={submitData}
					className="button button--blue"
				>
					Save
				</Button>
				<Button
					type="button"
					onClick={hideCategoryEditor}
					className="button button--orange-invert"
				>
					Cancel
				</Button>
			</div>
		</div>
	);
};

export default CategoryDescriptionEditor;
